<template>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>
<v-container>
  <div class="row">
    <div class="col-md-5"></div>
  <div class="col-md-2">
    <div class="row">
      <div class="col-md-12">
        <a href='/sales' class="btn btn-info" style="width:100%;color:white;background:#003f75!important;border-color:#003f75!important">Rapport Ventes</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a href='/productsreport' class="btn btn-warning" style="width:100%;color:white;background:#003f75!important;border-color:#003f75!important">Rapport Produits</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a  href='/agents' class="btn btn-primary" style="width:100%;color:white;background:#003f75!important;border-color:#003f75!important">Rapport Agents</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a  href='/closing' class="btn btn-success" style="width:100%;color:white;background:#003f75!important;border-color:#003f75!important">Rapport de Caisse</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a  href='/catalog' class="btn btn-danger" style="width:100%;color:white;background:#003f75!important;border-color:#003f75!important">Mises à Jours Produits</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a class="btn btn-info"  href='/rates' style="width:100%;color:white;background:#003f75!important;border-color:#003f75!important">Taux du Jour</a>
      </div>
    </div>
  </div>
  </div>
  
  
</v-container>
</div>


</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'

export default {
  name: 'HomeView',
  data: () => ({
      items: [
        {
          text: 'ACCUEIL',
          disabled: false,
          href: '/',
        }
      ],
      fluid: true,
      business: [],
      profile: [],
    }),
  components: { BreadcrumbComponent },
}
</script>

<style type="text/css">
  .v-data-table__wrapper{
    border: 2px solid #f3f3f3;
  }
</style>